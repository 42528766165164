import React, { useState, useEffect } from "react";
import {
  ButtonWhatsapp,
  CardRoom,
  ContainerExtraInformation,
  ContainerReviews,
  Footer,
  Header,
  ReservationCardBoho,
  ReservationCardRetro,
} from "../../components";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { IoSnow, IoThermometerOutline } from "react-icons/io5";
import { GiKnifeFork } from "react-icons/gi";
import {
  FaWifi,
  FaTimes,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaBath,
} from "react-icons/fa";
import { RiHotelLine } from "react-icons/ri";
import { BsHouseDoor, BsPeople } from "react-icons/bs";
import { BiBathtub, BiBuildings } from "react-icons/bi";
import Essentials from "../../assets/svg/essentials";
import Parking from "../../assets/svg/parking";
import styled from "styled-components";
import "flatpickr/dist/themes/light.css";
import Gallery from "react-grid-gallery";
import { api } from "../../api";
import { useTranslation } from "react-i18next";

const ContainerHeroImages = styled.div`
  padding-bottom: 90%;
  @media (min-width: 768px) {
    padding-bottom: 46%;
  }
`;

function Room(props) {
  const state = props.location.state;
  const [dataRoom, setDataRoom] = useState(state);
  const { t, i18n } = useTranslation();
  const [showGallery, setShowGallery] = useState(false);
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState(api.data);
  const [currency, setCurrency] = useState();
  console.log(data);
  useEffect(() => {
    setDataRoom(state);
    let currencySelected = localStorage.currency
      ? localStorage.currency
      : "usd";
    fetch(
      `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${currencySelected}.json`
    )
      .then((response) => response.json())
      .then((result) => {
        if (!state) {
          if (data.length > 1) {
            let idRoom = window.location.pathname.split("/")[2];
            let dataFiltered = api.data.filter(
              (item) => item.category_id == idRoom
            );
            setDataRoom(dataFiltered[0]);
          } else {
            setDataRoom(data[0]);
          }
        }
        setCurrency(result[currencySelected].cop);
      })
      .catch((error) => console.log("error", error));
  }, [state]);
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  const getImagesGrid = () => {
    let photos = [];
    for (let index = 0; index < 6; index++) {
      if (dataRoom.photos[index]) {
        photos.push(dataRoom.photos[index].url);
      }
    }
    return photos;
  };
  const images = dataRoom
    ? dataRoom.photos.map((item) => ({ original: item.url }))
    : [];
  const imagesGrid =
    dataRoom &&
    getImagesGrid().map((item) => ({
      src: item,
      thumbnail: item,
      thumbnailWidth: 100,
    }));
  return (
    <div className="max-w-6xl mx-auto">
      <Header block={true} dataApi={api.data} />
      {dataRoom && (
        <>
          <ContainerHeroImages
            className="flex relative cursor-pointer rounded-roomImages border border-transparent overflow-hidden"
            onClick={() => setShowGallery(true)}
          >
            <figure
              className="md:border-r-roomImages border-primaryBackground h-full w-full md:w-4/6 absolute bg-cover"
              style={{ backgroundImage: `url(${dataRoom.photos[0].url})` }}
            ></figure>
            <div className="hidden md:block w-2/6 top-0 right-0 bottom-0 absolute">
              <figure
                className="md:border-l-roomImages border-b-roomImages border-primaryBackground h-1/2 w-full absolute right-0 top-0 bg-cover"
                style={{ backgroundImage: `url(${dataRoom.photos[1].url})` }}
              ></figure>
              <figure
                className="md:border-l-roomImages border-t-roomImages border-primaryBackground h-1/2 w-full absolute right-0 bottom-0 bg-cover"
                style={{ backgroundImage: `url(${dataRoom.photos[2].url})` }}
              ></figure>
            </div>
            <button
              onClick={() => setShowGallery(true)}
              className="absolute text-gray-800 bg-white border-2 hover:bg-black hover:text-white font-semibold text-sm px-2 py-1 border-black right-2 bottom-2"
            >
              {dataRoom.photos.length} PHOTOS
            </button>
          </ContainerHeroImages>
          <div className="px-6 pt-8 pb-12 max-w-6xl flex md:justify-around text-black">
            <div className="text-center mt-36 hidden lg:block">
              <h3 className="font-titlesFamily text-xs text-gray-600">SHARE</h3>
              <ul>
                <li>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook size={20} className="mx-auto my-6" />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${dataRoom.name}&url=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter size={20} className="mx-auto my-6" />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://api.whatsapp.com/send?phone=573142379206&text=Me%20interesa%20este%20alojamiento: ${dataRoom.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={20} className="mx-auto my-6" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="md:w-3/5">
              <div>
                {process.env.REACT_APP_THEME === "retro" ||
                process.env.REACT_APP_THEME === "minimal" ? (
                  <>
                    <h2
                      className="italic inline pr-2 font-titlesFamily"
                      style={{
                        backgroundColor: "#e4f2f3",
                      }}
                    >
                      {dataRoom.name}
                    </h2>
                    <p className="font-bold text-sm mt-3 mb-8 uppercase">
                      <u>{dataRoom.location.country}</u> /{" "}
                      <u>{dataRoom.location.state}</u> /{" "}
                      <u>{dataRoom.location.city}</u>
                      {/* / <u>TASMANIA</u> / HOBART */}
                    </p>
                    <div className="flex mb-8 text-sm">
                      <div className="flex items-center mr-6">
                        {dataRoom.property_type == "House" ? (
                          <BsHouseDoor size={18} />
                        ) : dataRoom.property_type == "Apartment" ? (
                          <BiBuildings size={18} />
                        ) : (
                          <RiHotelLine size={18} />
                        )}
                        <p className="ml-2">{dataRoom.property_type}</p>
                      </div>
                      <div className="flex items-center mr-6">
                        <BsPeople size={18} />
                        <p className="ml-2">
                          {dataRoom.capacity} {t("room_page.guests")} (
                          {dataRoom.number_beds} {t("room_page.beds")})
                        </p>
                      </div>
                      <div className="flex items-center mr-6">
                        <FaBath size={18} />
                        <p className="ml-2">
                          {dataRoom.number_bathrooms} {t("room_page.bathrooms")}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h2>{dataRoom.name}</h2>
                    <p className="mb-8 mt-2">
                      {dataRoom.location.city}, {dataRoom.location.state}
                    </p>
                  </>
                )}
                {dataRoom.descriptions[i18n.language].paragraphs.map((text) => (
                  <p className="text-xl mb-6 font-medium">{text}</p>
                ))}
              </div>
              <div>
                <h2 className="text-lg font-semibold tracking-wider mb-6 mt-10 uppercase font-titlesFamily">
                  {t("room_page.facilities")}
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-y-3 gap-x-2">
                  {dataRoom.facilities.includes("wifi") && (
                    <div className="flex items-center">
                      <FaWifi size="20" />
                      <p className="ml-2">Wi-Fi (free)</p>
                    </div>
                  )}
                  {dataRoom.facilities.includes("parking") && (
                    <div className="flex items-center">
                      <Parking width="20" />
                      <p className="ml-2">Parking</p>
                    </div>
                  )}
                  {dataRoom.facilities.includes("essentials") && (
                    <div className="flex items-center">
                      <Essentials width="20" />
                      <p className="ml-2">Essentials</p>
                    </div>
                  )}
                  {dataRoom.facilities.includes("breakfast") && (
                    <div className="flex items-center">
                      <GiKnifeFork size="20" />
                      <p className="ml-2">Breakfast</p>
                    </div>
                  )}
                  {dataRoom.facilities.includes("heating") && (
                    <div className="flex items-center">
                      <IoThermometerOutline size="20" />
                      <p className="ml-2">Heating</p>
                    </div>
                  )}
                  {dataRoom.facilities.includes("air_conditioning") && (
                    <div className="flex items-center">
                      <IoSnow size="20" />
                      <p className="ml-2">Air conditioning</p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <h2 className="text-lg font-semibold tracking-wider mb-6 mt-10 uppercase font-titlesFamily">
                  {t("room_page.more_photos")}
                </h2>
                <Gallery
                  images={imagesGrid}
                  maxRows={4}
                  enableImageSelection={false}
                />
              </div>
              <div className="inline-block">
                <h2 className="text-lg font-semibold tracking-wider mb-6 mt-10 uppercase font-titlesFamily">
                  {t("room_page.location")}
                </h2>
                {dataRoom.location.description[i18n.language].map((text) => (
                  <p className="text-xl mb-6 font-medium">{text}</p>
                ))}
              </div>
              <div>
                <h2 className="text-lg font-semibold tracking-wider mb-6 mt-10 uppercase font-titlesFamily">
                  {t("room_page.how_to_get")}
                </h2>
                {dataRoom.location.how_to_get[i18n.language].map((text) => (
                  <p className="text-xl mb-6 font-medium">{text}</p>
                ))}
              </div>
              <div>
                <iframe
                  src={dataRoom.location.map_url}
                  allowfullscreen=""
                  className="w-full h-72"
                  loading="lazy"
                ></iframe>
                <div className="md:flex items-center">
                  <p className="mr-2">
                    <strong>{t("room_page.address")}:</strong>{" "}
                    {dataRoom.location.address}
                  </p>
                  <a
                    href={dataRoom.location.map_url}
                    target="_blank"
                    className="text-sm border-b-2 border-black font-bold uppercase"
                  >
                    {t("room_page.view_larger_map")}
                  </a>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 right-0 left-0 md:w-2/5 md:relative md:max-w-xs">
              <div className="fixed z-10 bottom-0 left-0 right-0 md:sticky md:top-6 md:inset-0">
                {process.env.REACT_APP_THEME === "retro" ? (
                  <ReservationCardRetro
                    valueCurrency={currency}
                    dataRoom={dataRoom}
                  />
                ) : (
                  <ReservationCardBoho />
                )}
                <div className="md:hidden items-center border-t border-gray-200 flex bg-white shadow-md border py-2">
                  <div className="w-1/2 px-4">
                    <p className="text-xl font-bold text-primaryColor mt-3 inline">
                      $
                      {numberWithCommas((dataRoom.price / currency).toFixed(0))}
                    </p>
                    <p className="hidden md:block">
                      {t("room_page.night_oropendolo")}
                    </p>
                  </div>
                  <a
                    href="https://booking.oropendolo.com/"
                    className="text-white w-1/2 flex justify-between bg-primaryColor font-bold text-md py-3 px-2 text-left pl-3 rounded-sm"
                  >
                    {t("room_page.check_availability")}
                    <span>→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="px-6 md:pl-24">
            <ContainerReviews dataRoom={dataRoom} />
          </div>
          <div className="px-6 md:pl-24">
            <ContainerExtraInformation
              security={dataRoom.security}
              rulesHome={dataRoom.rules}
            />
          </div>
          {showGallery && (
            <div className="fixed bg-black inset-0 flex items-center justify-center z-20">
              <ImageGallery
                items={images}
                showBullets={true}
                showIndex={true}
                showThumbnails={false}
                lazyLoad={true}
                showPlayButton={false}
                useBrowserFullscreen={true}
              />
              <p
                onClick={() => setShowGallery(false)}
                className="absolute text-white top-2 right-3 cursor-pointer"
              >
                <FaTimes size="24" />
              </p>
            </div>
          )}
          {data.length > 1 && (
            <div className="px-6 pt-14">
              <h3 className="font-titlesFamily font-semibold tracking-wider text-xl mb-5 uppercase">
                {t("room_page.nearby")}
              </h3>
              <div className="grid md:grid-cols-4 gap-4">
                <>
                  {data[0] && (
                    <CardRoom valueCurrency={currency} data={data[0]} />
                  )}
                  {data[1] && (
                    <CardRoom valueCurrency={currency} data={data[1]} />
                  )}
                  {data[2] && (
                    <CardRoom valueCurrency={currency} data={data[2]} />
                  )}
                  {data[3] && (
                    <CardRoom valueCurrency={currency} data={data[3]} />
                  )}
                </>
              </div>
            </div>
          )}
        </>
      )}
      <Footer />
      <ButtonWhatsapp />
    </div>
  );
}

export default Room;
