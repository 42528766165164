import React from "react";
import { CancellationPolicy, HealthAndSecurity, RulesHome } from "..";

function ContainerExtraInformation({ rulesHome, security }) {
  return (
    <>
      <RulesHome rulesHome={rulesHome} />
      <HealthAndSecurity security={security} />
      <CancellationPolicy security={security} />
    </>
  );
}

export default ContainerExtraInformation;
