import React from "react";
import { useTranslation } from "react-i18next";
import { FaStar } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import GoogleIcon from "../../assets/images/google-icon.jpeg";
import AirbnbIcon from "../../assets/images/airbnb-icon.png";
// import { ItemFeaturesBar } from "..";

function ModalReviews({ reviews, setShowModal }) {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 bg-white z-10 px-4 overflow-y-auto text-black">
      <div className="container mx-auto">
        <div className="sticky top-0 bg-white pb-8 pt-4">
          <IoIosArrowBack
            className="cursor-pointer mb-3"
            size="24"
            onClick={() => setShowModal(false)}
          />
          <div className="flex text-3xl font-semibold items-center">
            <FaStar size="16" className="mr-2" />
            <p>
              {reviews.global_score} · {reviews.data.length}{" "}
              {t("room_page.reviews.reviews")}
            </p>
          </div>
        </div>
        {/* <div>
        <ItemFeaturesBar feature="Limpieza" score={reviews.clean_score} />
        <ItemFeaturesBar
          feature="Fiabilidad"
          score={reviews.reliability_score}
        />
        <ItemFeaturesBar
          feature="Comunicación"
          score={reviews.communication_score}
        />
        <ItemFeaturesBar feature="Ubicación" score={reviews.location_score} />
        <ItemFeaturesBar feature="Llegada" score={reviews.checkin_score} />
        <ItemFeaturesBar feature="Precio" score={reviews.price_score} />
      </div> */}
        <div className="grid md:grid-cols-2 gap-2">
          {reviews.data.map((review) => (
            <div className="my-8">
              <div className="flex items-center mb-3">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={review.origin === "Google" ? GoogleIcon : AirbnbIcon}
                />
                <div className="ml-3">
                  <p className="font-semibold text-lg">{review.user.author}</p>
                  <p className="text-gray-500">noviembre de 2021</p>
                </div>
              </div>
              <p>{review.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ModalReviews;
