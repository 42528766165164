import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      aria-hidden="true"
      style={{
        height: 16,
        width: 16,
      }}
      display="block"
      fill="currentcolor"
      {...props}
    >
      <path d="M12.5 1A2.5 2.5 0 0115 3.5v9a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 011 12.5v-9A2.5 2.5 0 013.5 1zM5.846 9H4.515A3.632 3.632 0 007 11.485v-1.33A2.384 2.384 0 015.846 9zm5.64 0h-1.332a2.384 2.384 0 01-1.153 1.154v1.331A3.632 3.632 0 0011.484 9zM8 7a1 1 0 100 2 1 1 0 000-2zM7 4.515A3.632 3.632 0 004.515 7h1.33A2.384 2.384 0 017 5.845zm2 0v1.33c.508.237.919.647 1.155 1.155h1.33A3.632 3.632 0 009 4.515zM12.75 2.5a.75.75 0 100 1.5.75.75 0 000-1.5z" />
    </svg>
  );
}

export default SvgComponent;
