import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <path
        fill={props.color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.28.797c-.324-.324-.84-.682-1.486-.775-.692-.098-1.423.124-2.074.775a.75.75 0 101.06 1.06c.349-.348.618-.376.801-.35.229.033.463.175.639.35.075.077.358.581-.5 1.44-.532.53-.887.989-1.065 1.594-.124.42-.15.884-.154 1.416L.986 9.125a.75.75 0 00-.486.702v2.5c0 .415.336.75.75.75H2.5V19c0 .414.336.75.75.75h12A.75.75 0 0016 19v-5.923h1.25a.75.75 0 00.75-.75v-2.5a.75.75 0 00-.486-.702L10.002 6.31c.006-.495.025-.767.093-.995.072-.245.217-.487.685-.956 1.142-1.142 1.425-2.637.5-3.56zM16 11.577h.5v-1.23L9.25 7.628 2 10.348v1.23h.5v-.75a.75.75 0 01.75-.75h12a.75.75 0 01.75.75v.75zM4 18.25v-6.673h1v6.673H4zm2.5 0v-6.673h8v6.673h-8z"
      />
    </svg>
  );
}

export default SvgComponent;
