import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{
        height: 16,
        width: 16,
      }}
      display="block"
      fill="currentcolor"
      {...props}
    >
      <path d="M5 5v.414l4 4v3.172L4.464 17.12C3.527 18.06 3 18.674 3 20v9c0 1.644 1.356 2.982 3 3 6.645-.012 10.645-.012 12 0 1.657.014 3-1 3-3v-9c0-1.351-.547-2.126-1.516-3.067L15 12.577V9a2.99 2.99 0 012.083-2.842 8.957 8.957 0 002.17 4.798l1.499-1.324A6.959 6.959 0 0119.078 6H23V0H10a5 5 0 00-5 5zm14-3h2v2h-2zm-1.032 22.686h.004c.348 0 .69-.034 1.028-.084v2.02c-.34.039-.682.064-1.028.064h-.004a8.946 8.946 0 01-6.362-2.636A6.948 6.948 0 006.659 22h-.003A7.07 7.07 0 005 22.204v-2.048c.54-.1 1.093-.156 1.655-.156h.005a8.94 8.94 0 016.36 2.636 6.956 6.956 0 004.948 2.05zM11 10h2v2h-2zm17-9a1 1 0 112 0 1 1 0 01-2 0zm2 4a1 1 0 11-2 0 1 1 0 012 0zm-3-2a1 1 0 11-2 0 1 1 0 012 0z" />
    </svg>
  );
}

export default SvgComponent;
