import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BurgerMenu } from "..";
import Logo from "../../assets/images/logo.png";

const HeaderContainer = styled.header`
  position: ${(props) => (props.block ? "relative" : "absolute")};
`;

function Header({ block, dataApi }) {
  const [showCurrencies, setShowCurrencies] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <HeaderContainer block={block} className="top-0 right-0 left-0 mx-auto">
      <div className="max-w-6xl flex justify-between items-center mx-auto py-4 px-6">
        <Link to="/" className="flex">
          <img src={Logo} alt="logo" className="w-auto mr-4" />
        </Link>
        <nav>
          <ul className="hidden md:flex">
            <Link
              to="/"
              className={`uppercase font-semibold text-black text-sm ml-5 cursor-pointer ${
                window.location.pathname === "/" && "border-b-2 border-black"
              }`}
            >
              {t("header.home")}
            </Link>
            {dataApi.length > 1 && (
              <Link
                to="/listings"
                className={`uppercase font-semibold text-black text-sm ml-5 cursor-pointer ${
                  window.location.pathname !== "/" && "border-b-2 border-black"
                }`}
              >
                {t("header.listings")}
              </Link>
            )}
            <li
              onClick={() => {
                localStorage.setItem(
                  "lang",
                  i18n.language === "es" ? "en" : "es"
                );
                i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
              }}
              className="uppercase font-semibold text-black text-sm ml-5 cursor-pointer"
            >
              {i18n.language === "es" ? "ES" : "EN"}
            </li>
            <li className="uppercase font-semibold text-black text-sm ml-5 cursor-pointer relative">
              <p onClick={() => setShowCurrencies(!showCurrencies)}>
                {localStorage.currency
                  ? localStorage.currency.toUpperCase()
                  : "USD"}
              </p>
              {showCurrencies && (
                <ul className="absolute flex flex-col bg-white border-2 border-black right-0 top-9 z-10">
                  <li
                    className="uppercase font-semibold text-black text-sm mx-auto cursor-pointer flex py-2 px-3 border-b border-gray-200 w-full"
                    onClick={() => {
                      localStorage.setItem("currency", "usd");
                      window.location.reload();
                    }}
                  >
                    <p>USD</p>
                    <p className="whitespace-nowrap ml-4">
                      UNITED STATED DOLLAR
                    </p>
                  </li>
                  <li
                    className="uppercase font-semibold text-black text-sm mx-auto cursor-pointer flex py-2 px-3 border-b border-gray-200 w-full"
                    onClick={() => {
                      localStorage.setItem("currency", "cop");
                      window.location.reload();
                    }}
                  >
                    <p>COP</p>
                    <p className="whitespace-nowrap ml-4">COLOMBIAN PESO</p>
                  </li>
                  <li
                    className="uppercase font-semibold text-black text-sm mx-auto cursor-pointer flex py-2 px-3 border-b border-gray-200 w-full"
                    onClick={() => {
                      localStorage.setItem("currency", "eur");
                      window.location.reload();
                    }}
                  >
                    <p>EUR</p>
                    <p className="whitespace-nowrap ml-4">EURO</p>
                  </li>
                </ul>
              )}
            </li>
          </ul>
          <BurgerMenu dataApi={dataApi} />
        </nav>
      </div>
    </HeaderContainer>
  );
}

export default Header;
