import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 18 25" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0L0 5.143v19.16h18V5.143L9 0zM2 6.303l7-4 7 4v16H2v-1h10v-2H2v-1h8v-2H2v-1h6v-2H2v-1h4v-2H2v-1h2v-2H2v-1z"
      />
    </svg>
  );
}

export default SvgComponent;
