import React from "react";
import styled from "styled-components";
import GoogleIcon from "../../assets/images/google-icon.jpeg";
import AirbnbIcon from "../../assets/images/airbnb-icon.png";

const Container = styled.div`
  @media (max-width: 768px) {
    flex: 0 0 80%;
  }
`;

function ItemReview({ review }) {
  return (
    <Container className="border border-gray-300 rounded-containerReview p-3 mr-2 w-4/5 md:w-2/6 overflow-x-auto text-black">
      <div className="flex items-center">
        <img
          className="w-10 h-10 rounded-full object-cover"
          src={review.origin === "Google" ? GoogleIcon : AirbnbIcon}
        />
        <div className="ml-3">
          <p className="font-semibold text-lg">{review.user.author}</p>
          <p className="text-gray-500">noviembre de 2021</p>
        </div>
      </div>
      <p>{review.text}</p>
    </Container>
  );
}

export default ItemReview;
