import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{
        height: 16,
        width: 16,
      }}
      display="block"
      fill="currentcolor"
      {...props}
    >
      <path d="M3.707 2.293l26 26-1.414 1.414-26-26 1.414-1.414zm8.164 13.821l11.149 11.15a3.993 3.993 0 01-3.053.612l-.234-.055L16 26.82l-3.733 1.001a4 4 0 01-2.941-.347l-.204-.118-.197-.131a4 4 0 01-1.08-5.398l.119-.177 3.907-5.536zM27 12a4 4 0 110 8 4 4 0 010-8zM5 12a4 4 0 110 8 4 4 0 010-8zm16-8a4 4 0 110 8 4 4 0 010-8zM11 4a4 4 0 013.381 6.138l-5.52-5.519A3.981 3.981 0 0111 4z" />
    </svg>
  );
}

export default SvgComponent;
