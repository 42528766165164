import React, { useState, useEffect } from "react";
import { CardRoom, ContainerListingsBoho, Header } from "../../components";
import { api } from "../../api";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ContainerListingsRetro from "../../components/ContainerListingsRetro";

const ContainerFilterItem = styled.div`
  &:hover div {
    background-color: black;
  }
  div {
    background-color: ${(props) => props.selected && "black"};
  }
`;

function Listings() {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState();
  const [data, setData] = useState(api.data);
  const [dataFiltered, setDataFiltered] = useState({
    property_type: null,
    location: null,
    price: null,
  });

  useEffect(() => {
    let currencySelected = localStorage.currency
      ? localStorage.currency
      : "usd";
    fetch(
      `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${currencySelected}.json`
    )
      .then((response) => response.json())
      .then((result) => setCurrency(result[currencySelected].cop))
      .catch((error) => console.log("error", error));
  }, []);
  useEffect(() => {
    var filtered = api.data;
    if (dataFiltered.property_type) {
      filtered = filtered.filter(
        (item) => item.property_type === dataFiltered.property_type
      );
    }
    if (dataFiltered.location) {
      filtered = filtered.filter(
        (item) => item.location.country === dataFiltered.location
      );
    }
    if (dataFiltered.price) {
      filtered = filtered.filter((item) => {
        if (
          dataFiltered.price === 100000000 &&
          item.price > 700000 &&
          item.price < 100000000
        ) {
          return true;
        } else if (
          dataFiltered.price === 700000 &&
          item.price > 500000 &&
          item.price < 700000
        ) {
          return true;
        } else if (
          dataFiltered.price === 400000 &&
          item.price > 150000 &&
          item.price < 400000
        ) {
          return true;
        } else if (
          dataFiltered.price === 150000 &&
          item.price > 0 &&
          item.price < 150000
        ) {
          return true;
        }
      });
    }
    setData(filtered);
  }, [dataFiltered]);

  let filters = {
    locations: [],
    property_types: [],
  };
  api.data.forEach(({ location, property_type }) => {
    if (!filters.locations.includes(location.country)) {
      filters.locations.push(location.country);
    }
    if (!filters.property_types.includes(property_type)) {
      filters.property_types.push(property_type);
    }
  });
  return (
    <>
      <Header dataApi={api.data} block={true} />
      <div className="flex max-w-6xl mx-auto px-6">
        <p className="border-b-2 border-black pb-2 text-sm font-semibold">
          {t("listings.list_view")}
        </p>
      </div>
      <section className="bg-white px-6 py-10 text-black">
        <div className="flex max-w-6xl mx-auto">
          <div className="hidden md:block whitespace-nowrap mr-12">
            <div className="mb-5">
              <h3 className="text-sm font-semibold font-titlesFamily">
                {t("listings.popular_locations")}
              </h3>
              <ul>
                {filters.locations.map((item) => (
                  <ContainerFilterItem
                    selected={item === dataFiltered.location}
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      if (item === dataFiltered.location) {
                        setDataFiltered({ ...dataFiltered, location: null });
                      } else {
                        setDataFiltered({ ...dataFiltered, location: item });
                      }
                    }}
                  >
                    <div className="w-2 h-2 border-2 mr-2 border-black" />
                    <li className="text-sm my-1 cursor-pointer hover:underline">
                      {item}
                    </li>
                  </ContainerFilterItem>
                ))}
              </ul>
            </div>
            <div className="mb-5">
              <h3 className="text-sm font-semibold font-titlesFamily">
                {t("listings.property_type")}
              </h3>
              <ul>
                {filters.property_types.map((item) => (
                  <ContainerFilterItem
                    selected={item === dataFiltered.property_type}
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      if (item === dataFiltered.property_type) {
                        setDataFiltered({
                          ...dataFiltered,
                          property_type: null,
                        });
                      } else {
                        setDataFiltered({
                          ...dataFiltered,
                          property_type: item,
                        });
                      }
                    }}
                  >
                    <div className="w-2 h-2 border-2 mr-2 border-black" />
                    <li className="text-sm my-1 cursor-pointer hover:underline">
                      {item}
                    </li>
                  </ContainerFilterItem>
                ))}
              </ul>
            </div>
            <div className="mb-5">
              <h3 className="font-titlesFamily text-sm font-semibold">
                {t("listings.price_night")}
              </h3>
              <ul>
                <ContainerFilterItem
                  selected={150000 === dataFiltered.price}
                  className="flex items-center cursor-pointer"
                  onClick={() =>
                    setDataFiltered({ ...dataFiltered, price: 150000 })
                  }
                >
                  <div className="w-2 h-2 border-2 mr-2 border-black" />
                  <li className="text-sm my-1">$</li>
                </ContainerFilterItem>
                <ContainerFilterItem
                  selected={400000 === dataFiltered.price}
                  className="flex items-center cursor-pointer"
                  onClick={() =>
                    setDataFiltered({ ...dataFiltered, price: 400000 })
                  }
                >
                  <div className="w-2 h-2 border-2 mr-2 border-black" />
                  <li className="text-sm my-1">$$</li>
                </ContainerFilterItem>
                <ContainerFilterItem
                  selected={700000 === dataFiltered.price}
                  className="flex items-center cursor-pointer"
                  onClick={() =>
                    setDataFiltered({ ...dataFiltered, price: 700000 })
                  }
                >
                  <div className="w-2 h-2 border-2 mr-2 border-black" />
                  <li className="text-sm my-1">$$$</li>
                </ContainerFilterItem>
                <ContainerFilterItem
                  selected={100000000 === dataFiltered.price}
                  className="flex items-center cursor-pointer"
                  onClick={() =>
                    setDataFiltered({ ...dataFiltered, price: 100000000 })
                  }
                >
                  <div className="w-2 h-2 border-2 mr-2 border-black" />
                  <li className="text-sm my-1">$$$$+</li>
                </ContainerFilterItem>
              </ul>
            </div>
          </div>
          <div className="w-full">
            {process.env.REACT_APP_THEME === "retro" ? (
              <ContainerListingsRetro data={data} currency={currency} />
            ) : process.env.REACT_APP_THEME === "boho" ? (
              <ContainerListingsBoho currency={currency} data={data} />
            ) : (
              <div className="grid md:grid-cols-3 gap-4">
                {data.length > 0 &&
                  data.map((item) => (
                    <CardRoom valueCurrency={currency} data={item} />
                  ))}
              </div>
            )}
            {data.length === 0 && (
              <>
                <h3 className="font-titlesFamily font-bold text-3xl mb-3">
                  {t("listings.no_results_title")}
                </h3>
                <p>{t("listings.no_results_subtitle_1")}</p>
                <p>{t("listings.no_results_subtitle_2")}</p>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Listings;
