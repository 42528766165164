import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { ItemReview, ModalReviews } from "..";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  @media (max-width: 768px) {
    flex: 0 0 80%;
  }
`;

function ContainerReviews({ dataRoom: { reviews } }) {
  const [showModalReviews, setShowModalReviews] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      {reviews.data.length > 0 ? (
        <>
          <div className="flex text-2xl font-semibold items-center text-black">
            <FaStar size="16" className="mr-2" />
            <p>
              {reviews.global_score} · {reviews.data.length}{" "}
              {t("room_page.reviews.reviews")}
            </p>
          </div>
          <div className="flex overflow-x-auto py-2">
            {reviews.data.map(
              (item, index) =>
                index < 3 && <ItemReview key={index} review={item} />
            )}
            {reviews.data.length > 3 && (
              <Container className="border border-gray-300 rounded-containerReview p-3 mr-2 w-4/5 md:w-2/6 overflow-x-auto flex items-center justify-center">
                <p
                  className="underline text-lg font-semibold text-gray-500 cursor-pointer"
                  onClick={() => setShowModalReviews(true)}
                >
                  {t("room_page.reviews.show_all_reviews")}{" "}
                  {reviews.data.length} {t("room_page.reviews.reviews")}
                </p>
              </Container>
            )}
          </div>
          <button
            onClick={() => setShowModalReviews(true)}
            className="border w-full border-gray-600 rounded-md py-3 text-lg font-semibold mt-3"
          >
            {t("room_page.reviews.show_all_reviews")} {reviews.data.length}{" "}
            {t("room_page.reviews.reviews")}
          </button>
        </>
      ) : (
        <> </>
      )}
      {showModalReviews && (
        <ModalReviews setShowModal={setShowModalReviews} reviews={reviews} />
      )}
    </>
  );
}

export default ContainerReviews;
