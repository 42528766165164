import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HomeRarestays from "../../assets/svg/homeRarestays";
import { FaGoogle, FaInstagram, FaWhatsapp, FaFacebook } from "react-icons/fa";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="flex px-5 py-10 justify-between items-center border-t border-gray-200 max-w-7xl mx-auto text-black">
      <div className="flex items-end">
        <p className="flex text-sm items-start font-semibold underline">
          <HomeRarestays width="12" className="mr-1" />
          Haciendacsqno.com
        </p>
        <p className="ml-1 text-sm">© 2021 {t("footer.copyright")}.</p>
      </div>
      <div className="text-sm hidden md:flex">
        <ul className="flex">
          <Link
            to="/contact"
            className="font-semibold hover:text-blue-500 underline ml-2 cursor-pointer"
          >
            {t("footer.contact")}
          </Link>
          <Link
            to="/privacy"
            className="font-semibold hover:text-blue-500 underline ml-2 cursor-pointer"
          >
            {t("footer.privacy")}
          </Link>
          <Link
            to="/terms"
            className="font-semibold hover:text-blue-500 underline ml-2 cursor-pointer"
          >
            {t("footer.terms")}
          </Link>
        </ul>
        <ul className="flex">
          <li className="font-semibold underline ml-2 cursor-pointer">
            <a
              href="https://www.instagram.com/haciendacsqno/?hl=es"
              target="_blank"
            >
              <FaInstagram size={16} />
            </a>
          </li>
          <li className="font-semibold underline ml-2 cursor-pointer">
            <a
              href="https://api.whatsapp.com/send?phone=573142379206"
              target="_blank"
            >
              <FaWhatsapp size={16} />
            </a>
          </li>
          {/*<li className="font-semibold underline ml-2 cursor-pointer">
            <a href="https://facebook.com" target="_blank">
              <FaFacebook size={16} />
            </a>
          </li>*/}
          <li className="font-semibold underline ml-2 cursor-pointer">
            <a href="https://g.page/haciendacsqno?share" target="_blank">
              <FaGoogle size={16} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
