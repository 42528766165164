import React, { useEffect, useRef, useState } from "react";
import { CgMenuLeft } from "react-icons/cg";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GrClose } from "react-icons/gr";
import {
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";

function useOutsideAlerter(ref, action) {
  useEffect(() => {
    /**
     * change state if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function BurgerMenu({ children, dataApi }) {
  const [show, setShow] = useState(false);
  const [transition, setTransition] = useState(false);
  const [showCurrencies, setShowCurrencies] = useState(false);
  const { t, i18n } = useTranslation();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setTransition);

  return (
    <>
      <div className="block md:hidden">
        {show && (
          <div
            className="bg-gray-400 fixed top-0 left-0 bottom-0 bg-opacity-20 z-10"
            style={{ width: "100vw" }}
          >
            <CSSTransition
              classNames="burgerMenu"
              in={transition}
              appear={transition}
              onExited={() => setShow(false)}
              timeout={400}
            >
              <div
                ref={wrapperRef}
                className="bg-white h-full w-4/5 md:w-96 pt-4 shadow absolute right-0 top-0 bottom-0"
              >
                <div className="flex justify-between px-7">
                  <div>
                    <Link
                      to="/"
                      className="my-3 block font-semibold text-black"
                    >
                      HOME
                    </Link>
                    {dataApi.length > 1 && (
                      <Link
                        to="/listings"
                        className="mb-8 block font-semibold text-black"
                      >
                        LISTINGS
                      </Link>
                    )}
                  </div>
                  <GrClose
                    color="black"
                    size="20"
                    className="text-gray-400 cursor-pointer"
                    onClick={() => {
                      setTransition(false);
                    }}
                  />
                </div>
                <p
                  onClick={() => {
                    localStorage.setItem(
                      "lang",
                      i18n.language === "es" ? "en" : "es"
                    );
                    i18n.changeLanguage(i18n.language === "es" ? "en" : "es");
                  }}
                  className="uppercase font-semibold text-black text-sm cursor-pointer px-7 mb-4"
                >
                  {i18n.language === "es" ? "ES" : "EN"}
                </p>
                <div className="uppercase font-semibold text-black text-sm px-7 mb-4 cursor-pointer relative">
                  <p onClick={() => setShowCurrencies(!showCurrencies)}>
                    {localStorage.currency
                      ? localStorage.currency.toUpperCase()
                      : "USD"}
                  </p>
                  {showCurrencies && (
                    <ul className="flex flex-col">
                      <li
                        className="uppercase font-semibold text-black text-sm mx-auto cursor-pointer flex py-2 px-3 border-b border-gray-200 w-full"
                        onClick={() => {
                          localStorage.setItem("currency", "usd");
                          window.location.reload();
                        }}
                      >
                        <p>USD</p>
                        <p className="whitespace-nowrap ml-4">
                          UNITED STATED DOLLAR
                        </p>
                      </li>
                      <li
                        className="uppercase font-semibold text-black text-sm mx-auto cursor-pointer flex py-2 px-3 border-b border-gray-200 w-full"
                        onClick={() => {
                          localStorage.setItem("currency", "cop");
                          window.location.reload();
                        }}
                      >
                        <p>COP</p>
                        <p className="whitespace-nowrap ml-4">COLOMBIAN PESO</p>
                      </li>
                      <li
                        className="uppercase font-semibold text-black text-sm mx-auto cursor-pointer flex py-2 px-3 border-b border-gray-200 w-full"
                        onClick={() => {
                          localStorage.setItem("currency", "eur");
                          window.location.reload();
                        }}
                      >
                        <p>EUR</p>
                        <p className="whitespace-nowrap ml-4">EURO</p>
                      </li>
                    </ul>
                  )}
                </div>

                <div className="bg-primaryBackground px-7 h-full">
                  <div className="py-5 border-b border-gray-200">
                    <a
                      href="https://www.instagram.com/haciendacsqno/"
                      target="_blank"
                      className="flex items-center uppercase text-sm text-primaryBlackGray my-3"
                    >
                      <FaInstagram size="18" color="gray" className="mr-3" />
                      Instagram
                    </a>
                    <a
                      href="https://facebook.com"
                      target="_blank"
                      className="flex items-center uppercase text-sm text-primaryBlackGray my-3"
                    >
                      <FaFacebook size="18" color="gray" className="mr-3" />
                      Facebook
                    </a>
                    <a
                      href="https://twitter.com"
                      target="_blank"
                      className="flex items-center uppercase text-sm text-primaryBlackGray my-3"
                    >
                      <FaTwitter size="18" color="gray" className="mr-3" />
                      Twitter
                    </a>
                  </div>

                  <div className="py-5">
                    <Link
                      to="/terms"
                      className="uppercase block text-sm text-primaryBlackGray my-3"
                    >
                      Terms
                    </Link>
                    <Link
                      to="/contact"
                      className="uppercase block text-sm text-primaryBlackGray my-3"
                    >
                      Contact
                    </Link>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        )}
        <div
          onClick={() => {
            setShow(true);
            setTransition(true);
          }}
        >
          <CgMenuLeft
            color="black"
            size="26"
            className="text-gray-400 cursor-pointer"
          />
        </div>
      </div>
      {children}
    </>
  );
}

export default BurgerMenu;
