import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{
        height: 16,
        width: 16,
      }}
      display="block"
      fill="currentcolor"
      {...props}
    >
      <path d="M27 29V3a2 2 0 00-2-2H7a2 2 0 00-2 2v26H3v2h26v-2zm-8.5-11a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM25 29h-2V3h2z" />
    </svg>
  );
}

export default SvgComponent;
