import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import ClockIcon from "../../assets/svg/clock.js";
import SmokingIcon from "../../assets/svg/nosmoking.js";
import PetsIcon from "../../assets/svg/nopets.js";
import DoorIcon from "../../assets/svg/door.js";
import PartyIcon from "../../assets/svg/noparty.js";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function RulesHomeModal({ setShowModal, rulesHome }) {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 bg-white px-4 z-20 overflow-y-auto text-black">
      <div className="container mx-auto">
        <IoIosArrowBack
          className="cursor-pointer mb-3 mt-4"
          size="24"
          onClick={() => setShowModal(false)}
        />
        <h3 className="font-semibold text-2xl font-titlesFamily">
          {t("room_page.house_rules.house_rules")}
        </h3>
        <div>
          <div className="flex mt-2">
            <ClockIcon className="mt-1" />
            <p className="mx-2" style={{ width: "fit-content" }}>
              Check-in: {t("room_page.house_rules.hour_checkin")}{" "}
              {rulesHome.check_in}
            </p>
          </div>
          <div className="flex mt-2">
            <ClockIcon className="mt-1" />
            <p className="mx-2" style={{ width: "fit-content" }}>
              {t("room_page.house_rules.check_out")}: {rulesHome.check_out}
            </p>
          </div>
          <div className="flex mt-2">
            <DoorIcon className="mt-1" />
            <p className="mx-2" style={{ width: "fit-content" }}>
              {rulesHome.early_check
                ? t("room_page.house_rules.early_check_true")
                : t("room_page.house_rules.early_check_false")}
            </p>
          </div>

          <div className="flex mt-2">
            <SmokingIcon className="mt-1" />
            <p className="mx-2" style={{ width: "fit-content" }}>
              {rulesHome.smoking
                ? t("room_page.house_rules.smoking_true")
                : t("room_page.house_rules.smoking_false")}
            </p>
          </div>
          <div className="flex mt-2">
            <PetsIcon className="mt-1" />
            <p className="mx-2" style={{ width: "fit-content" }}>
              {rulesHome.pets
                ? t("room_page.house_rules.pets_true")
                : t("room_page.house_rules.pets_false")}
            </p>
          </div>
          <div className="flex mt-2">
            <PartyIcon className="mt-1" />
            <p className="mx-2" style={{ width: "fit-content" }}>
              {rulesHome.pets
                ? t("room_page.house_rules.events_true")
                : t("room_page.house_rules.events_false")}
            </p>
          </div>
        </div>
        <div>
          <p className="text-lg font-semibold mt-5">
            {t("room_page.house_rules.additional_rules")}
          </p>
          {rulesHome.additional_rules[i18next.language].paragraphs.map(
            (rule) => (
              <p>{rule}</p>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default RulesHomeModal;
