import React from "react";
import { api } from "../../api";
import { Footer, Header } from "../../components";

function Privacy() {
  return (
    <>
      <Header block="true" dataApi={api.data} />
      <section className="bg-white">
        <div class="bg-white max-w-4xl mx-auto pt-10 text-lg px-8">
          <h1 className="font-bold font-titlesFamily text-5xl mb-12">
            Privacy Policy
          </h1>
          <p className="my-5">
            This privacy policy has been compiled to better serve those who are
            concerned with how their 'Personally identifiable information' (PII)
            is being used online. PII, as used in US privacy law and information
            security, is information that can be used on its own or with other
            information to identify, contact, or locate a single person, or to
            identify an individual in context. Please read our privacy policy
            carefully to get a clear understanding of how we collect, use,
            protect or otherwise handle your Personally Identifiable Information
            in accordance with our website.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            What personal information do we collect from the people that visit
            our blog, website or app?
          </h4>
          <p className="my-5">
            When ordering or registering on our site, as appropriate, you may be
            asked to enter your name, email address or other details to help you
            with your experience.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            When do we collect information?
          </h4>
          <p className="my-5">
            We collect information from you when you register on our site or
            enter information on our site.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            How do we use your information?
          </h4>
          <p className="my-5">
            We may use the information we collect from you when you register,
            make a purchase, sign up for our newsletter, respond to a survey or
            marketing communication, surf the website, or use certain other site
            features in the following ways:
          </p>
          <ul>
            <li>
              To personalize user's experience and to allow us to deliver the
              type of content and product offerings in which you are most
              interested.
            </li>
          </ul>
          <h4 className="font-titlesFamily font-semibold">
            How do we protect visitor information?
          </h4>
          <ul>
            <li>
              We do not use vulnerability scanning and/or scanning to PCI
              standards.
            </li>
            <li>We use regular Malware Scanning.</li>
            <li>We use an SSL certificate</li>
            <li>
              We only provide articles and information, we never ask for
              personal or private information like email addresses, or credit
              card numbers.
            </li>
          </ul>
          <h4 className="font-titlesFamily font-semibold">
            Do we use 'cookies'?
          </h4>
          <p className="my-5">
            Yes. Cookies are small files that a site or its service provider
            transfers to your computer's hard drive through your Web browser (if
            you allow) that enables the site's or service provider's systems to
            recognize your browser and capture and remember certain information.
            For instance, we use cookies to help us remember you and keep you
            logged into the site. They are also used to help us understand your
            preferences based on previous or current site activity, which
            enables us to provide you with improved services. We also use
            cookies to help us compile aggregate data about site traffic and
            site interaction so that we can offer better site experiences and
            tools in the future.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            We use cookies to:
          </h4>
          <ul>
            <li>
              Understand and save user's preferences for future visits such as
              prefered currency or viewing mode.
            </li>
          </ul>
          <p className="my-5">
            You can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies. You do this
            through your browser (like Internet Explorer) settings. Each browser
            is a little different, so look at your browser's Help menu to learn
            the correct way to modify your cookies.
          </p>
          <p className="my-5">
            If you disable cookies off, some features will be disabled It won't
            affect the users experience that make your site experience more
            efficient and some of our services will not function properly.
          </p>
          <h4 className="font-titlesFamily font-semibold">Third party links</h4>
          <p className="my-5">
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We therefore have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
          <h4 className="font-titlesFamily font-semibold">Google</h4>
          <p className="my-5">
            Google's advertising requirements can be summed up by Google's
            Advertising Principles. They are put in place to provide a positive
            experience for users.{" "}
            <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">
              https://support.google.com/adwordspolicy/answer/1316548?hl=en
            </a>
          </p>
          <p className="my-5">
            We have not enabled Google AdSense on our site but we may do so in
            the future.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            California Online Privacy Protection Act
          </h4>
          <p className="my-5">
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law's
            reach stretches well beyond California to require a person or
            company in the United States (and conceivably the world) that
            operates websites collecting personally identifiable information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals with whom it is being shared, and to comply with
            this policy. - See more at:{" "}
            <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">
              http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
            </a>
          </p>
          <h4 className="font-titlesFamily font-semibold">
            According to CalOPPA we agree to the following:
          </h4>
          <ul>
            <li>Users can visit our site anonymously</li>
          </ul>
          <p className="my-5">
            Once this privacy policy is created, we will add a link to it on our
            home page, or as a minimum on the first significant page after
            entering our website.
          </p>
          <p className="my-5">
            Our Privacy Policy link includes the word 'Privacy', and can be
            easily be found on the page specified above.
          </p>
          <p className="my-5">
            Users will be notified of any privacy policy changes on our Privacy
            Policy Page
          </p>
          <p className="my-5">
            Users are able to change their personal information by logging in to
            their account
          </p>
          <h4 className="font-titlesFamily font-semibold">
            How does our site handle do not track signals?
          </h4>
          <p className="my-5">
            We honor do not track signals and do not track, plant cookies, or
            use advertising when a Do Not Track (DNT) browser mechanism is in
            place.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            Does our site allow third party behavioral tracking?
          </h4>
          <p className="my-5">
            No, our site does do not allow third party behavioral tracking
          </p>
          <h4 className="font-titlesFamily font-semibold">
            COPPA (Children Online Privacy Protection Act)
          </h4>
          <p className="my-5">
            When it comes to the collection of personal information from
            children under 13, the Children's Online Privacy Protection Act
            (COPPA) puts parents in control. The Federal Trade Commission, the
            nation's consumer protection agency, enforces the COPPA Rule, which
            spells out what operators of websites and online services must do to
            protect children's privacy and safety online.
          </p>
          <p className="my-5">
            We do not specifically market to children under 13.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            Fair Information Practices
          </h4>
          <p className="my-5">
            The Fair Information Practices Principles form the backbone of
            privacy law in the United States and the concepts they include have
            played a significant role in the development of data protection laws
            around the globe. Understanding the Fair Information Practice
            Principles and how they should be implemented is critical to comply
            with the various privacy laws that protect personal information.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            In order to be in line with Fair Information Practices we will take
            the following responsive action, should a data breach occur:
          </h4>
          <p className="my-5">
            We will notify the users via email within 7 business days
          </p>
          <p className="my-5">
            We also agree to the individual redress principle, which requires
            that individuals have a right to pursue legally enforceable rights
            against data collectors and processors who fail to adhere to the
            law. This principle requires not only that individuals have
            enforceable rights against data users, but also that individuals
            have recourse to courts or a government agency to investigate and/or
            prosecute non-compliance by data processors.
          </p>
          <h4 className="font-titlesFamily font-semibold">CAN SPAM Act</h4>
          <p className="my-5">
            The CAN-SPAM Act is a law that sets the rules for commercial email,
            establishes requirements for commercial messages, gives recipients
            the right to have emails stopped from being sent to them, and spells
            out tough penalties for violations.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            We collect your email address in order to:
          </h4>
          <p className="my-5">
            Send information, respond to inquiries, and/or other requests or
            questions.
          </p>
          <h4 className="font-titlesFamily font-semibold">
            To be in accordance with CANSPAM we agree to the following:
          </h4>
          <ul>
            <li>NOT use false, or misleading subjects or email addresses</li>
            <li>
              Identify the message as an advertisement in some reasonable way
            </li>
            <li>
              Include the physical address of our business or site headquarters
            </li>
            <li>
              Monitor third party email marketing services for compliance, if
              one is used.
            </li>
            <li>Honor opt-out/unsubscribe requests quickly</li>
            <li>
              Allow users to unsubscribe by using the link at the bottom of each
              email
            </li>
          </ul>
          <p className="my-5">
            If at any time you would like to unsubscribe from receiving future
            emails, just follow the instructions at the bottom of each email and
            we will promptly remove you from ####ALL#### correspondence.
          </p>
          <h4 className="font-titlesFamily font-semibold">Contacting Us</h4>
          <p className="my-5">
            If there are any questions regarding this privacy policy you may
            contact us using the information below.
          </p>
          <p className="my-5">
            <a href="https://www.rarestays.com">https://www.rarestays.com</a>
            <br />
            41 Stewart St
            <br />
            Richmond, Victoria 3121
            <br />
            Australia{" "}
          </p>
          <p className="my-5">rarestays@gmail.com</p>
          <p className="my-5">
            <small>Last Edited on 2018-06-28</small>
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Privacy;
