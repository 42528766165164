import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";
import { RulesHomeModal } from "..";

function RulesHome({ rulesHome }) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div
        className="flex items-center border-t my-4 pt-4 mx-4 cursor-pointer text-black"
        onClick={() => setShowModal(true)}
      >
        <div className="w-full">
          <p className="text-2xl font-semibold">
            {t("room_page.house_rules.house_rules")}
          </p>
          <p className="text-gray-600">
            Check-in: {t("room_page.house_rules.hour_checkin")}{" "}
            {rulesHome.check_in}
          </p>
        </div>
        <FiChevronRight size={26} />
      </div>
      {showModal && (
        <RulesHomeModal rulesHome={rulesHome} setShowModal={setShowModal} />
      )}
    </>
  );
}

export default RulesHome;
