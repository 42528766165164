import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CardContainer = styled.div`
  figure {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPUlEQVQoU2M0tbH7f/rIIUYGAgCsgBjFcJMIKUaxEp9iDLfhUozVE9gU4/QtumK8wYKsmGD4wRQTVAgLZwC5tSW5ll2wSgAAAABJRU5ErkJggg==);
    background-repeat: repeat;
    padding-bottom: 72%;
  }
  @media (max-width: 768px) {
    figure {
      padding-bottom: 36%;
    }
  }
  :hover img {
    opacity: 0.94;
  }
`;

function CardRoom({ data, valueCurrency }) {
  const { t } = useTranslation();
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  return (
    <Link
      to={{
        pathname: `/room/${data.category_id}`,
        state: data,
      }}
    >
      <CardContainer className="flex md:block items-center mb-6 cursor-pointer text-black rounded-roundedCards border border-transparent overflow-hidden">
        <figure className="w-1/2 md:w-full relative h-5/6">
          <img
            src={data.photos[0].url}
            className="absolute h-full w-full inset-0 object-cover"
          />
          <div className="flex border-2 border-black bg-white w-max items-center px-1 absolute opacity-0 md:opacity-100 bottom-1 left-1">
            <p className="font-semibold mr-1">
              ${numberWithCommas((data.price / valueCurrency).toFixed(0))}
            </p>
            <small>/{t("home_page.labels.night")}</small>
          </div>
        </figure>
        <div className="w-1/2 md:w-full px-5 md:bg-white py-2">
          <small className="uppercase text-xs">
            {data.location.state}, {data.location.country}
          </small>
          <p className="font-semibold my-2 md:mb-1 text-lg leading-none">
            {data.name}
          </p>
          <small className="text-gray-500 hidden md:block">
            {data.property_type} - {data.capacity}{" "}
            {t("home_page.labels.guests")}
          </small>
          <div className="flex border-2 border-black bg-white w-max items-center px-1 md:hidden">
            <p className="font-semibold mr-1">
              ${numberWithCommas((data.price / valueCurrency).toFixed(0))}
            </p>
            <small>/{t("home_page.labels.night")}</small>
          </div>
        </div>
      </CardContainer>
    </Link>
  );
}

export default CardRoom;
