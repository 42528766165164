import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 10a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0zm1.5 0c0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10S4.478 0 10 0c5.523 0 10 4.477 10 10zm-8.954-6.75H7.5a.75.75 0 00-.75.75v12.5h1.5v-4.66h2.796c1.274 0 2.266-.557 2.918-1.397.638-.82.934-1.876.934-2.898 0-1.02-.296-2.078-.934-2.898-.652-.84-1.644-1.397-2.918-1.397zm0 7.09H8.25V4.75h2.796c.793 0 1.353.329 1.734.818.397.51.618 1.226.618 1.977 0 .752-.221 1.467-.618 1.977-.38.49-.941.819-1.734.819z"
      />
    </svg>
  );
}

export default SvgComponent;
