import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeroImage from "../../assets/images/hero-image.jpeg";

function HeroRetro() {
  const { t } = useTranslation();
  const HeroContainer = styled.div`
    background-image: url(${HeroImage});
    max-height: 1000px;
    @media (min-width: 760px) {
      height: 98vh;
      min-height: 500px;
    }
    u {
      background-image: linear-gradient(
        to right,
        rgba(92, 163, 211, 0.45) 0,
        rgba(91, 162, 209, 0.43) 100%
      );
      text-decoration: none;
      background-position: 0 0.84em;
      background-repeat: repeat-x;
      background-size: 1px 10px;
    }
  `;
  return (
    <HeroContainer className="bg-cover bg-center overflow-hidden px-6 flex md:items-center">
      <div className="max-w-3xl mx-auto">
        <h2 className="font-titlesFamily text-4-5xl md:text-6xl leading-tight tracking-swide mt-32 md:mt-0 text-black md:text-heroSize text-center">
          {t("home_page.hero_text_1")}

          {t("home_page.hero_text_2")}
        </h2>
        <div className="my-14 text-center">
          <Link
            to="/listings"
            className="bg-primaryColor border-primaryColor border text-white uppercase font-bold px-8 py-3 rounded-button cursor-pointer"
          >
            {t("home_page.search")}
          </Link>
        </div>
      </div>
    </HeroContainer>
  );
}

export default HeroRetro;
