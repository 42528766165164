import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import CheckIcon from "../../assets/svg/check.js";
import CleanIcon from "../../assets/svg/clean.js";
import MonoxideIcon from "../../assets/svg/monoxide.js";
import SprayIcon from "../../assets/svg/spray.js";
import CardIcon from "../../assets/svg/card.js";
import FireAlarmIcon from "../../assets/svg/fireAlarm.js";
import { AiFillSound } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function HealthAndSecurityModal({ setShowModal, security }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="fixed inset-0 bg-white px-4 z-20 overflow-y-auto text-black">
        <div className="container mx-auto">
          <IoIosArrowBack
            className="cursor-pointer mb-3 mt-4"
            size="24"
            onClick={() => setShowModal(false)}
          />
          <h3 className="font-semibold text-2xl font-titlesFamily">
            {t("room_page.health_and_security.health_and_security")}
          </h3>
          <div className="flex items-center">
            <SprayIcon className="mr-2" />
            <p>
              {security.covid_protocol
                ? t("room_page.health_and_security.covid_protocol_true")
                : t("room_page.health_and_security.covid_protocol_false")}
            </p>
          </div>
          <div className="flex items-center">
            <AiFillSound className="mr-2" />
            <p>
              {security.noise
                ? t("room_page.health_and_security.noise_true")
                : t("room_page.health_and_security.noise_false")}
            </p>
          </div>
          <div className="flex items-center">
            <MonoxideIcon className="mr-2" />
            <p>
              {security.co2_alarm
                ? t("room_page.health_and_security.co2_alarm_true")
                : t("room_page.health_and_security.co2_alarm_false")}
            </p>
          </div>
          <div className="flex items-center">
            <FireAlarmIcon className="mr-2" />
            <p>
              {security.smoke_alarm
                ? t("room_page.health_and_security.smoke_alarm_true")
                : t("room_page.health_and_security.smoke_alarm_false")}
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mt-6 mb-2 font-titlesFamily">
              {t("room_page.health_and_security.thins_keep_mind")}
            </h3>
            {security.additional_security[i18next.language].paragraphs.map(
              (paragraph) => (
                <div className="flex items-center">
                  <CheckIcon className="mr-2" />
                  <p>{paragraph}</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HealthAndSecurityModal;
