import React, { useEffect, useState } from "react";
import {
  CardRoom,
  Footer,
  Header,
  HeroBoho,
  HeroRetro,
} from "../../components";
import styled from "styled-components";
import HeroImage from "../../assets/images/hero-image.jpeg";
import { BiSearch } from "react-icons/bi";
import { api } from "../../api";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const HeroContainer = styled.div`
  background-image: url(${HeroImage});
  max-height: 1000px;
  @media (min-width: 760px) {
    height: 98vh;
    min-height: 500px;
  }
  u {
    background-image: linear-gradient(
      to right,
      rgba(92, 163, 211, 0.45) 0,
      rgba(91, 162, 209, 0.43) 100%
    );
    text-decoration: none;
    background-position: 0 0.84em;
    background-repeat: repeat-x;
    background-size: 1px 10px;
  }
`;

function Home() {
  const [data, setData] = useState(api.data);
  const [currency, setCurrency] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://api.lobbypms.com/api/v1/rooms?api_token=w27csmlYpcDrynPteRwSQw771b6TbZTaJnkzaboW3XpjjVsHsIENGCwlgUcV",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    let currencySelected = localStorage.currency
      ? localStorage.currency
      : "usd";
    fetch(
      `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${currencySelected}.json`
    )
      .then((response) => response.json())
      .then((result) => setCurrency(result[currencySelected].cop))
      .catch((error) => console.log("error", error));
  }, []);
  return (
    <>
      <Helmet>
        <title>Oropendolo Hacienda CSQ'No</title>
      </Helmet>
      <Header dataApi={api.data} />
      {process.env.REACT_APP_THEME === "retro" ? (
        <HeroRetro />
      ) : process.env.REACT_APP_THEME === "boho" ? (
        <HeroBoho />
      ) : (
        <HeroContainer className="bg-cover bg-center overflow-hidden px-6 flex md:items-center">
          <div className="max-w-3xl mx-auto">
            <h2 className="font-titlesFamily text-4-5xl md:text-6xl leading-tight tracking-swide mt-32 md:mt-0 text-black md:text-heroSize">
              <span className="md:text-4xl md:block">
                {t("home_page.hero_text_1")}
              </span>{" "}
              {t("home_page.hero_text_2")}
            </h2>
            <div className="my-14 text-center">
              <Link
                to="/listings"
                className="bg-primaryBlackGray border-primaryBlackGray border text-white uppercase font-bold px-8 py-3 rounded-button cursor-pointer"
              >
                {t("home_page.search")}
              </Link>
            </div>
          </div>
        </HeroContainer>
      )}
      <section className="bg-primaryBackground">
        <div className="px-6 pt-16 text-primaryBlack max-w-6xl mx-auto">
          <h3 className="text-3xl mb-8 font-bold font-titlesFamily">
            {t("home_page.labels.recent_finds")}
          </h3>
          <div className="grid md:grid-cols-2 gap-4 md:mb-4">
            <CardRoom valueCurrency={currency} data={data[0]} />
            <CardRoom valueCurrency={currency} data={data[1]} />
          </div>
          <div className="grid md:grid-cols-4 gap-4">
            {data.length > 0 &&
              data.map(
                (item, index) =>
                  index >= 2 &&
                  index <= 5 && (
                    <>
                      <CardRoom valueCurrency={currency} data={item} />{" "}
                    </>
                  )
              )}
          </div>
        </div>
        <div className="px-6 pt-16 text-primaryBlack max-w-6xl mx-auto">
          <h3 className="text-3xl mb-8 font-bold font-titlesFamily">
            {t("home_page.labels.favorite_rentals")}
          </h3>
          <div className="grid md:grid-cols-4 gap-4">
            {data?.length > 0 &&
              data.map(
                (item, index) =>
                  item.our_favorite && (
                    <CardRoom valueCurrency={currency} data={item} />
                  )
              )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
