import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { api } from "../api";
import { ButtonWhatsapp } from "../components";
import { Contact, Home, Room, Privacy, Terms, Listings } from "../containers";
import ScrollToTop from "./ScrollToTop";

function App() {
  const SecureRoutes = () => {
    if (api.data.length > 1) {
      return (
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/listings" component={Listings} />
          <Route exact path="/room/:roomId" component={Room} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />

          <Route component={Home} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/" component={Room} />
          <Route exact path="/listings" component={Listings} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Redirect to="/" />
        </Switch>
      );
    }
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      {SecureRoutes()}
    </BrowserRouter>
  );
}

export default App;
