import React from "react";
import { api } from "../../api";
import { Footer, Header } from "../../components";

function Contact() {
  return (
    <>
      <Header block="true" dataApi={api.data} />
      <section className="bg-white py-14 px-8 text-lg text-gray-800">
        <div className="max-w-2xl mx-auto">
          <h1 className="text-5xl font-titlesFamily font-bold mb-16">
            Contact us
          </h1>
          <div>
            <p>You can get in touch with us by sending an email to:</p>
            <p>rarestays@gmail.com.</p>
          </div>
          <hr className="my-10 border-t-2 border-gray-900" />
          <div>
            <h3 className="text-xl font-bold mb-5 font-titlesFamily">
              Get your property listed
            </h3>
            <p>
              Do you own or manage a property that you think is worthy of being
              listed on our site? If so, please send us an email with some more
              information about your place. We'd love to hear from you and see
              if we can work together.
            </p>
          </div>
          <hr className="my-10 border-t-2 border-gray-900" />
          <div>
            <h3 className="text-xl font-bold mb-5 font-titlesFamily">
              Advertising
            </h3>
            <p>
              If you are interested in adversting on rarestays.com, please reach
              out to us using the same email above and we can give you more
              information on how that works.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
