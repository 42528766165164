import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      style={{
        height: 16,
        width: 16,
      }}
      display="block"
      fill="currentcolor"
      {...props}
    >
      <path d="M16 1C7.716 1 1 7.716 1 16s6.716 15 15 15 15-6.716 15-15S24.284 1 16 1zm7.895 22.954l-9.526-5.5a1.5 1.5 0 01-.743-1.154l-.007-.145v-11h3V16.29l8.776 5.067-1.5 2.598z" />
    </svg>
  );
}

export default SvgComponent;
