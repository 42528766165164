import React from "react";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import { Link } from "react-router-dom";

function CardListingBoho({ dataRoom, currency }) {
  const images = dataRoom
    ? dataRoom.photos.map((item) => ({
        original: item.url,
        originalHeight: "100px",
        originalWidth: "100px",
      }))
    : [];
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  console.log(dataRoom);
  const { t } = useTranslation();
  return (
    <div className="mr-4 relative">
      <div className="mb-4 mt-4">
        <p className="font-semibold text-2xl">{dataRoom.location.city}</p>
        <p>
          {dataRoom.location.state}, {dataRoom.location.country}
        </p>
      </div>
      <div className="flex">
        <div className="w-1/2">
          {/* <ImageGallery
          items={images}
          infinite={true}
          lazyLoad={true}
          showThumbnails={false}
          showNav={false}
          showFullscreenButton={false}
          useBrowserFullscreen={false}
          showPlayButton={false}
          showBullets={true}
          bulletClass="prueba"
        /> */}
          <img
            src={dataRoom.photos[0].url}
            className="w-full object-cover rounded-xl"
            style={{ height: "170px" }}
          />
        </div>
        <div className="w-1/2 ml-3">
          <p className="text-xs text-gray-600 font-semibold">
            ROOM TPYES & NIGHTLY RATES
          </p>
          <div className="flex mt-4">
            <p>{dataRoom.property_type}</p>
            <p className="ml-auto">
              from{" "}
              <span className="font-semibold">
                ${numberWithCommas((dataRoom.price / currency).toFixed(0))}
              </span>
            </p>
          </div>
          <Link
            to={{
              pathname: `/room/${dataRoom.category_id}`,
              state: dataRoom,
            }}
            className="mt-auto absolute bottom-0 right-4 border border-primaryColor bg-primaryColor px-4 py-2 rounded-2xl"
          >
            See what's available
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CardListingBoho;
