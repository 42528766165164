import React from "react";
import { useTranslation } from "react-i18next";

function ReservationCardBoho() {
  const { t } = useTranslation();
  return (
    <div className="hidden md:block text-right px-4">
      {/* <p>US$1,688</p>
      <small>Includes taxed and fees</small> */}
      <p
        className="text-2xl underline font-semibold"
        style={{ color: "#156d51" }}
      >
        US$170 / night
      </p>
      <p className="text-sm text-gray-700">{t("room_page.taxes_fees")}</p>
      <div className="w-full text-center mt-6">
        <button className="text-lg bg-primaryColor rounded-3xl px-10 py-2">
          Book
        </button>
      </div>
    </div>
  );
}

export default ReservationCardBoho;
