import React from "react";
import { FaWhatsapp } from "react-icons/fa";

function ButtonWhatsapp() {

  return (
    <a
      href="https://api.whatsapp.com/send?phone=573142379206"
      target="_blank"
      className="fixed bottom-20 z-20 right-6 p-3 rounded-full cursor-pointer"
      style={{ backgroundColor: "#25d366" }}
    >
      <FaWhatsapp color="white" size="30" />
    </a>
  );
}

export default ButtonWhatsapp;
