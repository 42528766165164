import React from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";

function CancellationPolicyModal({ setShowModal }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="fixed inset-0 bg-white px-4 z-30 overflow-y-auto text-black">
        <div className="container px-4 mx-auto">
          <IoIosArrowBack
            onClick={() => setShowModal(false)}
            className="cursor-pointer mb-3 mt-4"
            size="24"
          />
          <h3 className="font-semibold text-2xl font-titlesFamily">
            {t("room_page.cancellation_policy.cancellation_policy")}
          </h3>
          <p className="text-lg font-semibold mt-6">
            {t("room_page.cancellation_policy.cancel_before")}
          </p>
          <div className="flex mb-6 mt-2">
            <div className="mr-2">
              <p className="font-semibold">
                {t("room_page.cancellation_policy.48_hours")}
              </p>
              <p className="text-xs">
                {t("room_page.cancellation_policy.after_make_reservation")}
              </p>
            </div>
            <p>{t("room_page.cancellation_policy.full_refund")}</p>
          </div>
          <p>{t("room_page.cancellation_policy.policy")}</p>
        </div>
      </div>
    </>
  );
}

export default CancellationPolicyModal;
