import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./translations/en.json";
import translationES from "./translations/es.json";
// the translations
// (tip move them in a JSON file and import them)
const getLanguage = () => {
  let language =
    localStorage.getItem("lang") === null
      ? navigator.language.slice(0, 2)
      : localStorage.getItem("lang");
  if (language !== "es" && language !== "en") {
    localStorage.setItem("lang", "en");
    language = "en";
    console.log("language", language);
  } else {
    localStorage.setItem("lang", language);
  }
  return language;
};

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(), // Pending to change
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
